import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Screenshot from "../../images/nasapogs.png";

export default function NasaPogs() {
  return (
    <Layout>
      <Helmet>
        <title>NASA Pogs | Nathan Pasko</title>
        <meta name="description" content="A meditative micro-game that uses the Unity physics engine to approximate what it would be like to play with pogs alone in a darkened cabin. Expierience it on Mac, Windows, or Linux!" />
      </Helmet>
      <div className="project-page">
        <picture>
          <img
            src={Screenshot}
            alt=""
            height="1800"
            width="2280"
          />
        </picture>
        <h1>NASA Pogs</h1>
        <p>
          Created for Meditations, a yearlong collaborative electronic
          performance hosted at{" "}
          <a href="https://meditations.games" target="blank">
            meditations.games
          </a>
          .
        </p>
        <p>
          NASA Pogs is a little 90s nostalgia simulator made using the Unity
          physics engine and photos from NASA's website. The gold slammer is
          based on one I owned as a teenager.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/468256?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/nasa-pogs">NASA Pogs by A.V. Knights</a></iframe>      </div>
    </Layout>
  );
}
